"use client";

import {
  Button,
  CustomCheckbox,
  CustomInput,
  CustomSelect,
  Loader,
} from "@/components/core-components";
import {
  addAddress,
  fetchAddressDetails,
  fetchAddressTypes,
  fetchCity,
  fetchCountries,
  fetchCountryCode,
  fetchState,
  setAdddressDetail,
  setQuickView,
  updateAddress,
  updateProfileDetails,
  useAppDispatch,
  useAppSelector,
} from "@/store";
import {
  AddressSchema,
  FORM_CONSTANTS,
  GET_AddressType,
  STATUSES,
  Toast,
  TOAST_CONSTANTS,
} from "@/utils";
import { Form, Formik } from "formik";
import { useRouter } from "next/navigation";
import React, { useEffect, useMemo } from "react";

const AddressForm = ({
  address,
  modalData = { state: false, data: null },
  isBusiness = false,
}: {
  address?: GET_AddressType;
  modalData?: { state: boolean; data: any };
  isBusiness?: boolean;
}) => {
  const dispatch = useAppDispatch();

  const { profile_details } = useAppSelector((state) => state.auth);

  const { addressDetail, addressTypes, status } = useAppSelector(
    (state) => state.address
  );

  const { state: commonState } = useAppSelector((state) => state.common);

  const { countries, states, cities } = useAppSelector(
    (state) => state.location
  );

  const router = useRouter();

  const addresstoEdit = modalData.state ? addressDetail : address;

  const getAddressTypeOptions = useMemo(() => {
    return addressTypes
      .filter((type) => type.id !== 3)
      .map((addressType) => ({
        value: addressType.id,
        label: addressType.name,
      }));
  }, [countries.length]);

  const getCountryOptions = useMemo(() => {
    return countries.map((country) => ({
      value: country?.id,
      label: country?.country,
    }));
  }, [countries.length]);

  const getStateOptions = useMemo(() => {
    return states.map((state) => ({
      value: state.id,
      label: state.state,
    }));
  }, [states.length]);

  const getCityOptions = useMemo(() => {
    return cities.map((city) => ({
      value: city.id,
      label: city.city,
    }));
  }, [cities.length]);

  useEffect(() => {
    if (modalData && modalData?.data?.id) {
      dispatch(fetchAddressDetails({ address_id: modalData?.data?.id }));
    }

    dispatch(fetchCountries());
    dispatch(fetchAddressTypes());

    if (!isBusiness) {
      dispatch(fetchCountryCode());
    } else {
      dispatch(
        fetchState({
          country_id: 101,
        })
      );
    }

    return () => {
      dispatch(setAdddressDetail(null));
    };
  }, []);

  useEffect(() => {
    if (addresstoEdit && Object.keys(addresstoEdit).length > 0) {
      dispatch(
        fetchState({
          country_id: isBusiness ? 101 : addresstoEdit?.country?.id,
        })
      );
      dispatch(fetchCity({ state_id: addresstoEdit?.state?.id }));
    }
  }, [Object.keys(addresstoEdit || {}).length]);

  return (
    <div className="tab-pane fade active show">
      <div
        className={`${
          modalData.state ? "p-3 mb-10" : ""
        } card add-address-card`}
      >
        <div className="card-header p-0">
          <div className="section-title style-2 mb-0">
            <h3>
              {isBusiness
                ? `Business Details`
                : `${
                    addresstoEdit && Object.keys(addresstoEdit).length > 0
                      ? "Edit"
                      : "Add"
                  } Address`}
            </h3>
          </div>
        </div>
        <div
          className={`card-body pt-0 pb-0 pl-5 ${
            modalData.state && status === STATUSES.LOADING ? "text-center" : ""
          }`}
        >
          {modalData.state && status === STATUSES.LOADING ? (
            <div className="d-flex flex-row justify-content-center align-items-center">
              <Loader />
            </div>
          ) : (
            <Formik
              initialValues={{
                first_name:
                  isBusiness && profile_details
                    ? profile_details?.first_name
                    : addresstoEdit?.first_name || "",
                last_name:
                  isBusiness && profile_details
                    ? profile_details?.last_name
                    : addresstoEdit?.last_name || "",
                contact_number:
                  isBusiness && profile_details
                    ? profile_details?.contact_number
                    : addresstoEdit?.contact_number || "",
                country_code: [
                  {
                    country: "India",
                    phonecode: 91,
                    value: 101,
                    label: "IN +91",
                  },
                ],
                address_type: isBusiness
                  ? 3
                  : addresstoEdit?.address_type?.id || null,
                pincode: addresstoEdit?.pincode || "",
                address_line_1: addresstoEdit?.address_line_1 || "",
                address_line_2: addresstoEdit?.address_line_2 || "",
                landmark: addresstoEdit?.landmark || "",
                city: addresstoEdit?.city?.id || "",
                state: addresstoEdit?.state?.id || "",
                country: isBusiness ? 101 : addresstoEdit?.country?.id || "",
                is_default: Boolean(addresstoEdit?.set_as_default) || false,
                gstin: profile_details?.gstin || "",
                company_name: profile_details?.company_name || "",
                is_business: isBusiness,
              }}
              onSubmit={(values, actions) => {
                let dataToSend = {
                  first_name:
                    isBusiness && profile_details
                      ? profile_details?.first_name
                      : values?.first_name || "",
                  last_name:
                    isBusiness && profile_details
                      ? profile_details?.last_name
                      : values?.last_name || "",
                  pincode: values?.pincode,
                  address_line_1: values?.address_line_1,
                  address_line_2: values?.address_line_2,
                  landmark: values?.landmark,
                  city: Number(values?.city),
                  state: Number(values?.state),
                  country: isBusiness ? 101 : Number(values?.country),
                  address_type: isBusiness ? 3 : values?.address_type,
                  contact_number:
                    isBusiness && profile_details
                      ? profile_details?.contact_number
                      : Number(values?.contact_number),
                  country_code:
                    (isBusiness && profile_details
                      ? profile_details?.country?.id
                      : values?.country_code?.[0]?.value) || 101,
                  set_as_default: values?.is_default ? 1 : 0,
                };

                if (isBusiness && profile_details) {
                  const profileData = {
                    ...profile_details,
                    contact_number: Number(profile_details?.contact_number),
                    country: profile_details?.country?.id,
                    company_name: values?.company_name,
                    gstin: values?.gstin,
                    business_details: dataToSend,
                  };

                  dispatch(
                    updateProfileDetails({
                      data: profileData,
                      onSuccess: () => {
                        Toast({
                          message: "Updated Business Details",
                          type: TOAST_CONSTANTS.SUCCESS,
                        });
                        dispatch(
                          setQuickView({
                            state: false,
                            data: null,
                            type: "",
                          })
                        );
                      },
                    })
                  );

                  return;
                }

                if (addresstoEdit && Object.keys(addresstoEdit).length > 0) {
                  dispatch(
                    updateAddress({
                      data: {
                        ...dataToSend,
                        id: addresstoEdit.id,
                      },
                      actions,
                      router,
                      modal: modalData.state,
                      setAddress: modalData.state
                        ? modalData?.data?.setAddress
                        : undefined,
                    })
                  );
                } else {
                  dispatch(
                    addAddress({
                      data: dataToSend,
                      actions,
                      router,
                      modal: modalData.state,
                      setAddress: modalData.state
                        ? modalData?.data?.setAddress
                        : undefined,
                    })
                  );
                }

                if (isBusiness) {
                }
              }}
              validationSchema={AddressSchema}
            >
              {(addressProps) => {
                return (
                  <Form>
                    {!isBusiness && (
                      <div className="row">
                        <div className="col-md-6">
                          <CustomInput
                            placeholder="Enter First Name"
                            formikProps={addressProps}
                            label={"First Name"}
                            name={"first_name"}
                            isRequired={true}
                            type={FORM_CONSTANTS.TEXT}
                            titleCase={true}
                          />
                        </div>
                        <div className="col-md-6">
                          <CustomInput
                            placeholder="Enter Last Name"
                            formikProps={addressProps}
                            label={"Last Name"}
                            name={"last_name"}
                            isRequired={true}
                            type={FORM_CONSTANTS.TEXT}
                            titleCase={true}
                          />
                        </div>
                      </div>
                    )}
                    {isBusiness && (
                      <div className="row">
                        <div className="col-md-6">
                          <CustomInput
                            placeholder="Enter Company Name"
                            formikProps={addressProps}
                            label={"Company Name"}
                            name={"company_name"}
                            isRequired={addressProps.values?.is_business}
                            type={FORM_CONSTANTS.TEXT}
                          />
                        </div>
                        <div className="col-md-6">
                          <CustomInput
                            placeholder="Enter GSTIN"
                            formikProps={addressProps}
                            label={"GSTIN"}
                            name={"gstin"}
                            isRequired={addressProps.values?.is_business}
                            type={FORM_CONSTANTS.TEXT}
                          />
                        </div>
                      </div>
                    )}
                    {!isBusiness && (
                      <div className="row">
                        <div className="col-md-6">
                          <CustomInput
                            placeholder="Enter Mobile Number"
                            formikProps={addressProps}
                            countryCode={true}
                            countryCodeFieldName="country_code"
                            countryCodeClassName="col-4"
                            label={"Mobile Number"}
                            name={"contact_number"}
                            isRequired={true}
                            type={FORM_CONSTANTS.NUMBER}
                          />
                        </div>
                      </div>
                    )}
                    <div className="row">
                      {!isBusiness && (
                        <div className="col-md-2">
                          <CustomSelect
                            placeholder="Select Address Type"
                            disabled={isBusiness}
                            formikProps={addressProps}
                            className="form-group"
                            options={getAddressTypeOptions}
                            label="Address Type"
                            name="address_type"
                          />
                        </div>
                      )}
                      <div className={`col-md-${isBusiness ? 6 : 5}`}>
                        <CustomInput
                          placeholder="Enter Flat, House no., Building, Apartment"
                          formikProps={addressProps}
                          label={"Flat, House no., Building, Apartment "}
                          name={"address_line_1"}
                          isRequired={true}
                          type={FORM_CONSTANTS.TEXT}
                        />
                      </div>
                      <div className={`col-md-${isBusiness ? 6 : 5}`}>
                        <CustomInput
                          placeholder="Enter Area, Street, Sector, Village"
                          formikProps={addressProps}
                          label={"Area, Street, Sector, Village "}
                          name={"address_line_2"}
                          isRequired={true}
                          type={FORM_CONSTANTS.TEXT}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <CustomInput
                          placeholder="Enter Landmark"
                          formikProps={addressProps}
                          label={"Landmark"}
                          name={"landmark"}
                          isRequired={false}
                          type={FORM_CONSTANTS.TEXT}
                        />
                      </div>
                      <div className="col-md-6">
                        <CustomInput
                          placeholder="Enter Pincode"
                          formikProps={addressProps}
                          label={"Pincode"}
                          name={"pincode"}
                          isRequired={true}
                          type={FORM_CONSTANTS.TEXT}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <CustomSelect
                          placeholder="Select Country"
                          disabled={isBusiness}
                          formikProps={addressProps}
                          className="form-group"
                          isRequired={true}
                          options={getCountryOptions}
                          onChangeCapture={(e: any) => {
                            e.target.value &&
                              dispatch(
                                fetchState({
                                  country_id: Number(e.target.value),
                                })
                              );
                          }}
                          label="Country / Region"
                          name="country"
                        />
                      </div>
                      <div className="col-md-4">
                        <CustomSelect
                          placeholder="Select State"
                          formikProps={addressProps}
                          className="form-group"
                          isRequired={true}
                          options={getStateOptions}
                          onChangeCapture={(e: any) => {
                            e.target.value &&
                              dispatch(
                                fetchCity({
                                  state_id: Number(e.target.value),
                                })
                              );
                          }}
                          label="State"
                          name="state"
                        />
                      </div>
                      <div className="col-md-4">
                        <CustomSelect
                          placeholder="Select City"
                          formikProps={addressProps}
                          className="form-group"
                          isRequired={true}
                          options={getCityOptions}
                          label="City"
                          name="city"
                        />
                      </div>
                    </div>
                    <div className="form-group col-md-6 mt-10">
                      <div className="custome-checkbox">
                        <CustomCheckbox
                          name="is_default"
                          filters={[
                            {
                              id: 1,
                              value: "Make this my default address",
                            },
                          ]}
                          onClickHandler={(e: any) => {
                            addressProps.setFieldValue(
                              "is_default",
                              e.target.checked
                            );
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <Button
                        className="btn btn-sm btn-fill-out submit font-weight-bold"
                        type="submit"
                        text={isBusiness ? "Save Changes" : "Save Address"}
                        loading={
                          (commonState.status === STATUSES.LOADING &&
                            (commonState?.type?.includes("update-address") ||
                              commonState.type === "add-address")) ||
                          (commonState.type === "update-profile" && isBusiness)
                        }
                      />
                    </div>
                  </Form>
                );
              }}
            </Formik>
          )}
        </div>
      </div>
    </div>
  );
};

export { AddressForm };
