"use client";

import {
  Button,
  CustomCheckbox,
  CustomInput,
  CustomSelect,
} from "@/components/core-components";
import {
  resetPageLimit,
  setFilters,
  useAppDispatch,
  useAppSelector,
} from "@/store";
import { FORM_CONSTANTS, usePagination } from "@/utils";
import { Form, Formik } from "formik";
import { STATUSES } from "../../../utils/constants";
import React from "react";

const ExtraFiltersForm = ({
  taste = [],
  showLimit = true,
  clearBtnClick,
  clearBtnText,
  search = false,
  serverFilters = false,
  widgetIndex,
}: {
  taste?: any[];
  showLimit?: boolean;
  clearBtnClick?: () => void;
  clearBtnText?: string;
  search?: boolean;
  serverFilters?: boolean;
  widgetIndex: number;
}) => {
  const dispatch = useAppDispatch();

  const { filter, state } = useAppSelector((state) => state.common);

  const { additionalFilter, searchParams } = usePagination();

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        search: searchParams.get("query") || "",
        taste: searchParams.get("taste") || "",
        isJain: Number(searchParams.get("jain")) === 1,
        showLimit: Number(searchParams.get("limit")),
        sortBy: searchParams.get("sort_by") || "newest",
      }}
      onSubmit={() => {}}
    >
      {(applyFilterProps) => {
        return (
          <Form>
            <div className="sort-by-product-area d-flex flex-wrap align-items-center">
              {clearBtnClick && (
                <div className="mr-10">
                  <Button
                    className="btn btn-sm bg-red"
                    text={clearBtnText || "Clear All"}
                    type="button"
                    loading={
                      state.status === STATUSES.LOADING &&
                      state.type === "clear-wishlist"
                    }
                    onClick={clearBtnClick}
                  />
                </div>
              )}
              {search ? (
                <div className="sort-by-cover">
                  <CustomInput
                    formikProps={applyFilterProps}
                    name="search"
                    placeholder="Search"
                    type={FORM_CONSTANTS.TEXT}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        if (applyFilterProps.values.search) {
                          dispatch(
                            setFilters({
                              ...filter,
                              widgetIndex: widgetIndex,
                              query: applyFilterProps.values.search,
                            })
                          );
                          // updateSearch(applyFilterProps.values.search);
                        } else {
                          dispatch(
                            setFilters({
                              ...filter,
                              widgetIndex: widgetIndex,
                              query: "",
                            })
                          );
                          // updateSearch("");
                        }
                      }
                    }}
                  />
                </div>
              ) : (
                <React.Fragment>
                  <div className="custome-checkbox jain-ready-filter mr-10">
                    <CustomCheckbox
                      name="isJain"
                      filters={[
                        {
                          id: 1,
                          value: "Jain",
                        },
                      ]}
                      onClickHandler={(e: any) => {
                        if (e.target.checked) {
                          if (serverFilters) {
                            additionalFilter("jain", "1");
                            additionalFilter("page", "1");
                          } else {
                            dispatch(
                              setFilters({
                                ...filter,
                                widgetIndex: widgetIndex,
                                is_jain: 1,
                                page: 1,
                              })
                            );
                          }
                          // additionalFilter("jain", "1");
                        } else {
                          if (serverFilters) {
                            additionalFilter("jain");
                            additionalFilter("page", "1");
                          } else {
                            dispatch(
                              setFilters({
                                ...filter,
                                widgetIndex: widgetIndex,
                                is_jain: 0,
                                page: 1,
                              })
                            );
                          }

                          // additionalFilter("jain");
                        }
                        dispatch(resetPageLimit());
                      }}
                    />
                  </div>
                  <div className="sort-by-cover mr-10">
                    <CustomSelect
                      // formikProps={applyFilterProps}
                      name="taste"
                      inline={{
                        title: "Taste:",
                        icon: "fi-rs-apps",
                      }}
                      formValue={applyFilterProps.values.taste}
                      options={taste || []}
                      onChange={(e: any) => {
                        if (serverFilters) {
                          additionalFilter("taste", e.target.value.toString());
                          additionalFilter("page", "1");
                        } else {
                          dispatch(
                            setFilters({
                              ...filter,
                              widgetIndex: widgetIndex,
                              taste: e.target.value.toString(),
                              page: 1,
                            })
                          );
                        }
                        dispatch(resetPageLimit());

                        // additionalFilter("taste", e.target.value.toString());
                        // additionalFilter("page", "1");
                      }}
                      onBlur={(e: any) => {
                        if (serverFilters) {
                          additionalFilter("taste", e.target.value.toString());
                          additionalFilter("page", "1");
                        } else {
                          dispatch(
                            setFilters({
                              ...filter,
                              widgetIndex: widgetIndex,
                              taste: e.target.value.toString(),
                              page: 1,
                            })
                          );
                        }
                        dispatch(resetPageLimit());
                        // additionalFilter("taste", e.target.value.toString());
                        // additionalFilter("page", "1");
                      }}
                    />
                  </div>
                </React.Fragment>
              )}
              {!showLimit && (
                <div className="sort-by-cover mr-10">
                  <CustomSelect
                    // formikProps={applyFilterProps}
                    name="showLimit"
                    inline={{
                      title: "Show:",
                      icon: "fi-rs-apps",
                    }}
                    formValue={applyFilterProps.values.showLimit}
                    options={[
                      {
                        label: "6",
                        value: 6,
                      },
                      {
                        label: "12",
                        value: 12,
                      },
                      {
                        label: "18",
                        value: 18,
                      },
                    ]}
                    onChange={(e: any) => {
                      if (serverFilters) {
                        additionalFilter("limit", e.target.value.toString());
                        additionalFilter("page", "1");
                      } else {
                        dispatch(
                          setFilters({
                            ...filter,
                            widgetIndex: widgetIndex,
                            limit: e.target.value.toString(),
                            page: 1,
                          })
                        );
                      }
                      dispatch(resetPageLimit());

                      // additionalFilter("limit", e.target.value.toString());
                      // additionalFilter("page", "1");
                    }}
                    onBlur={(e: any) => {
                      if (serverFilters) {
                        additionalFilter("limit", e.target.value.toString());
                        additionalFilter("page", "1");
                      } else {
                        dispatch(
                          setFilters({
                            ...filter,
                            widgetIndex: widgetIndex,
                            limit: e.target.value.toString(),
                            page: 1,
                          })
                        );
                      }
                      dispatch(resetPageLimit());

                      // additionalFilter("limit", e.target.value.toString());
                      // additionalFilter("page", "1");
                    }}
                  />
                </div>
              )}
              <div className="sort-by-cover">
                <CustomSelect
                  // formikProps={applyFilterProps}
                  name="sortBy"
                  inline={{
                    title: "Sort By:",
                    icon: "fi-rs-apps-sort",
                  }}
                  formValue={applyFilterProps.values.sortBy}
                  options={[
                    {
                      label: "Newest",
                      value: "newest",
                    },
                    {
                      label: "Price: Low to High",
                      value: "price_low_to_high",
                    },
                    {
                      label: "Price: High to Low",
                      value: "price_high_to_low",
                    },
                  ]}
                  onChange={(e: any) => {
                    if (serverFilters) {
                      additionalFilter("sort_by", e.target.value.toString());
                    } else {
                      dispatch(
                        setFilters({
                          ...filter,
                          widgetIndex: widgetIndex,
                          sort_by: e.target.value.toString(),
                          page: 1,
                        })
                      );
                    }
                    dispatch(resetPageLimit());

                    // additionalFilter("sort_by", e.target.value.toString());
                  }}
                  onBlur={(e: any) => {
                    if (serverFilters) {
                      additionalFilter("sort_by", e.target.value.toString());
                    } else {
                      dispatch(
                        setFilters({
                          ...filter,
                          widgetIndex: widgetIndex,
                          sort_by: e.target.value.toString(),
                          page: 1,
                        })
                      );
                    }
                    dispatch(resetPageLimit());

                    // additionalFilter("sort_by", e.target.value.toString());
                  }}
                />
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export { ExtraFiltersForm };
