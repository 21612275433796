"use client";

import React, { useCallback, useMemo, useRef, useState } from "react";
import {
  advancedDebounce,
  GENERAL_CONSTANTS,
  STATUSES,
  trackSearch,
  usePagination,
} from "@/utils";
import { CustomMultiSelect } from "./CustomMultiSelect";
import {
  fetchSearchResults,
  resetSearchResults,
  useAppDispatch,
  useAppSelector,
} from "@/store";
import { useRouter } from "next/navigation";

const Search = ({ instanceID }: { instanceID: string }) => {
  const dispatch = useAppDispatch();

  const router = useRouter();

  const { searchOptions, state } = useAppSelector((state) => state.common);

  const [searchTerm, setSearchTerm] = useState("");

  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const { updateSearch } = usePagination();

  const getSearchResults = useMemo(() => {
    if (!searchOptions?.length) return [];

    const limitedOptions = searchOptions.slice(0, 10).map((product: any) => ({
      value: product.slug,
      label: product.title,
      updateSearchTerm: () => {},
    }));

    if (searchOptions.length > 10) {
      limitedOptions.push({
        value: `/search/?query=${searchTerm}&limit=12`,
        label: "See More ...",
        updateSearchTerm: () => {
          updateSearch(searchTerm);
        },
      });
    }

    return limitedOptions;
  }, [searchOptions?.length, state.status]);

  //Function for calling dispatch for Product search
  const searchValues = (query: string) => {
    if (query) {
      dispatch(resetSearchResults());
      dispatch(
        fetchSearchResults({
          query: query,
          onSuccess: () => setMenuIsOpen(true),
        })
      );
      trackSearch({ search_term: query });
    }
  };

  //Debounce function for search
  const optimizeSearch = useCallback(advancedDebounce(searchValues), []);

  const keyDownHandler = (event: any) => {
    switch (event.key) {
      case "Enter":
        event.stopPropagation();
        event.preventDefault();
        optimizeSearch(searchTerm);
        break;
    }
  };

  return (
    <CustomMultiSelect
      instanceId={instanceID}
      formatOptions={true}
      select={false}
      placeholder={"Search Avarya.in"}
      options={getSearchResults}
      value={
        searchTerm
          ? {
              value: searchTerm,
              label: searchTerm,
            }
          : null
      }
      menuIsOpen={menuIsOpen}
      inputValue={searchTerm}
      isLoading={
        state.status === STATUSES.LOADING &&
        state.type === "fetch-search-results"
      }
      onChangeHandler={(e, a) => {
        if (e !== null && e !== undefined) {
          e.value.includes("search")
            ? router.push(e.value)
            : router.push(`/${e.value}`);
        }
        if (a.action === GENERAL_CONSTANTS.CLEAR) {
          setSearchTerm("");
        }
        dispatch(resetSearchResults());
      }}
      onKeyDownHandler={keyDownHandler}
      onInputChangeHandler={(e) => {
        if (e.length === 0) {
          dispatch(resetSearchResults());
        }
        if (e.length > 3) {
          optimizeSearch(e);
        }
        setSearchTerm(e);
      }}
      menuCloseHandler={() => setMenuIsOpen(false)}
    />
  );
};

export { Search };
